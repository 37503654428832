<template>
  <form @submit.prevent>
    <a name="contact_error_success" />
    <UiAlert v-if="data?.status === 'success'" design="success" class="text-left mb-5">
      <UiLanguagePlaceholder domain="SharedNewsletterForm" name="success" />
    </UiAlert>
    <UiAlert v-if="data?.status === 'error'" design="error" class="text-left mb-5">
      <div class="font-bold">
        <UiLanguagePlaceholder domain="SharedNewsletterForm" name="error" />
      </div>
      <ul>
        <li v-if="data?.fields.email.status === 'error'">
          <UiLanguagePlaceholder domain="SharedNewsletterForm" name="error_email" />
        </li>
        <li v-if="data?.fields.email_exists.status === 'error'">
          <UiLanguagePlaceholder domain="SharedNewsletterForm" name="error_email_exists" />
        </li>
      </ul>
    </UiAlert>
    <div class="h-[1px] opacity-0 overflow-hidden">
      <input name="nein" title="nein" type="hidden">
      <input name="Realname" title="Realname" aria-label="Realname" type="text" placeholder="">
      <input name="ja" title="ja" style="display: none;" type="text" placeholder="">
      <input name="source" type="hidden" value="footer">
    </div>
    <div
      :class="[
        {'sm:flex': layoutBreak},
        {'flex': !layoutBreak},
        'gap-y-2'
      ]"
    >
      <UiInput
        name="email"
        :title="t('input_title')"
        :inputstyle="[
          {'rounded-r-none rounded-l-xl text-left w-full': !layoutBreak},
          {'rounded-r-xl sm:rounded-r-none rounded-l-xl text-center sm:text-left w-full': layoutBreak},
          'text-neutral-900'
        ]"
        style="position: inherit"
        :placeholder="t('placeholder')"
      />
      <UiButton
        color="petrol"
        :buttonstyle="[
          {'rounded-l-none rounded-r-xl w-auto': !layoutBreak},
          {'sm:rounded-l-none rounded-r-xl rounded-l-xl w-full sm:w-auto': layoutBreak}
        ]"
        buttonpadding="px-6 md:px-10 py-3"
        @click="sendForm"
      >
        {{ t('button') }}
      </UiButton>
    </div>
    <UiAlert v-if="props.error" design="error">
      {{ t('error') }}
    </UiAlert>
    <UiAlert v-if="props.errorEmptyInput" design="info">
      {{ t('error_empty_input') }}
    </UiAlert>
    <UiAlert v-if="props.success" design="success">
      {{ t('success') }}
    </UiAlert>
  </form>
</template>

<script setup>
const props = defineProps({
  error: {
    type: Boolean,
    default: false
  },
  errorEmptyInput: {
    type: Boolean,
    default: false
  },
  success: {
    type: Boolean,
    default: false
  },
  layoutBreak: {
    type: Boolean,
    default: true
  }
})
const t = useTranslations('SharedNewsletterForm').translate

const data = ref()
async function sendForm (event) {
  const form = event.target.closest('form')
  const formdata = new FormData(form)

  const { $backend } = useNuxtApp()
  data.value = await $backend.newsletterEntries.createNewsletterEntryFromClient(formdata)

  document.querySelector('a[name="contact_error_success"]')?.scrollIntoView()
}
</script>
